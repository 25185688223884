<template>
  <div>
    <!--    в кабинете ФОИВа видно контакты регионов и наоборот -->
    <ContactRegion v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE')"></ContactRegion>
    <ContactFoiv v-if="getUserRoles.find(el => el === 'REGION_ROLE')"></ContactFoiv>
    <!--    <ContactFoiv></ContactFoiv>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import ContactFoiv from "@/views/private/foivData/ContactsFoiv";
import ContactRegion from "@/views/private/regionData/ContactsRegion";

export default {
  name: "ViewContacts",
  components: {ContactFoiv, ContactRegion},
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
}
</script>

<style scoped>

</style>