<template>
  <div>
    <HeaderMain title="Контакты Субъектов РФ"/>
    <div v-for="(region,i) in regionsArray" :key="i">
      <!-- ????     v-on:toggle="getContacts(region.regionId, i)"-->
      <WrapperCollapse
        :sprContacts="true"
        :initialOpen="false"
        :title="region.regionName"
        @get-contact="getContacts(region.regionId)"
      >
        <!--        <div>-->
        <!--          <WrapperPreviewCategory customClass="section-preview_lk">-->
        <!--            <p>Субъект не указал ни один контакт</p>-->
        <!--          </WrapperPreviewCategory>-->
        <!--        </div>-->
        <div>
          <WrapperPreviewCategory
            customClass="section-preview_lk"
          >
            <ContainerPersonContact
              :key="region.regionId"
              :blocked="true"
              :can-be-deleted="false"
              :contact="contacts[region.regionId]"
            />
          </WrapperPreviewCategory>
        </div>
      </WrapperCollapse>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import request from "@/services/request";
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
import ContainerPersonContact from "@/containers/private/ContainerPersonContact";

export default {
  name: "ContactsRegion",
  components: {
    ContainerPersonContact,
    WrapperCollapse,
    WrapperPreviewCategory,
    HeaderMain
  },
  data: () => ({
    regionsArray: [],
    mainContact: {
      fio: '',
      email: '',
      position: '',
      workPhoneNumber: '',
      personalPhoneNumber: '',
    },
    contacts: {
      0: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
      },
    }
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
    }),
  },
  created() {
    this.getAllSprRegion();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    
    async getAllSprRegion() {
      try {
        const res = await request({
          endpoint: `/api/spr/region/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.regionsArray = res.data;
          console.log('getAllSprRegion успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getContacts(id,) {
      let uri = `/api/person/direct/getAllRegionId?regionId=${id}`
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.$set(this.contacts, id, res.data[0])
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.yyy {
  display: flex;
  flex-direction: row;
}

.collapse__toggle {
  margin-bottom: 10px !important;
}
</style>