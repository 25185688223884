<template>
  <div>
    <HeaderMain title="Контакты ФОИВов"/>
    <div v-for="(foiv, i) in foivArray" :key="i">
<!--   ????   v-on:toggle="getContacts(foiv.founderId, i)"-->
      <WrapperCollapse
        :sprContactsFoiv="true"
        :initialOpen="false"
        :title="foiv.orgFounderName"
        :text="codeTransform(foiv.sprCoUgs)"
        @get-foiv-contact="getContacts(foiv.founderId)"
      >
        <!--        <div>-->
        <!--          <WrapperPreviewCategory customClass="section-preview_lk">-->
        <!--            <p>Субъект не указал ни один контакт</p>-->
        <!--          </WrapperPreviewCategory>-->
        <!--        </div>-->
        <div>
          <WrapperPreviewCategory
            customClass="section-preview_lk"
          >
            <!--    :contact="contacts[0]"-->
            <ContainerPersonContact
              :key="foiv.founderId"
              :blocked="true"
              :can-be-deleted="false"
              :contact="contacts[foiv.founderId]"
            />
          </WrapperPreviewCategory>
        </div>
      </WrapperCollapse>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import request from "@/services/request";
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
import ContainerPersonContact from "@/containers/private/ContainerPersonContact";

export default {
  name: "ContactsFoiv",
  components: {
    WrapperCollapse,
    HeaderMain,
    WrapperPreviewCategory,
    ContainerPersonContact
  },
  data: () => ({
    foivArray: [],
    mainContact: {
      fio: '',
      email: '',
      position: '',
      workPhoneNumber: '',
      personalPhoneNumber: '',
    },
    contacts: {
      0: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
      },
    }
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
    }),
  },
  created() {
    this.getAllSprFounder();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    
    codeTransform(item) {
      let val = '';
      for (let y = 0; y < item.length; y++) {
        if (y + 1 >= item.length) {
          val = val + `${item[y].sprUgs.ugsCode}`
        } else {
          val = val + `${item[y].sprUgs.ugsCode}` + ',  '
        }
      }
      return val
    },
    
    async getAllSprFounder() {
      try {
        const res = await request({
          endpoint: `/api/spr/founder/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          const filteredData = res.data.filter(el => el.sprCoUgs.length > 0);
          this.foivArray = filteredData
          console.log('getAllSprFounder успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async getContacts(id) {
      let uri = `/api/person/direct/getAllFoivId?founderId=${id}`
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.$set(this.contacts, id, res.data[0])
        }
        console.log('getContacts успех');
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.yyy {
  display: flex;
  flex-direction: row;
}

.collapse__toggle {
  margin-bottom: 10px !important;
}
</style>